import React from "react";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { useStyles } from "../styles";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import classnames from "classnames";

export const EmptyStateVid = ({ className }) => {
  const isDesktop = useDesktopMediaQuery();
  const style = useStyles({ isDesktop })();

  return (
    <div className={classnames(style.imageContainer, className)}>
      <div>
        <PlayCircleOutlineIcon />
        <div className={style.imageText}>No video added yet</div>
      </div>
    </div>
  );
};
