import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ isDesktop }) => ({
  paddedCard: {
    padding: "18px 14px 23px 14px",
  },
  headerComponent: {
    display: ({ isDesktop }) => (isDesktop ? "flex" : "block"),
    alignItems: "start",
    gap: ({ isDesktop }) => (isDesktop ? "24px" : "8px"),
    "&>span": {
      flexGrow: "1",
      marginBottom: "7px",
    },
  },
  headerDropDownWrapper: {
    flexGrow: "1",
    maxWidth: ({ isDesktop }) => isDesktop && "300px",
    marginBottom: ({ isDesktop }) => (!isDesktop ? "8px" : "0.5rem"),
    display: "flex",
    flexDirection: "column",
  },
  cardTitleIcon: {
    margin: "0 12px 0 0",
    color: "#B8B4AD",
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    fontWeight: "600",
  },
  cardTitleIconComplete: {
    color: "#00B779",
  },
  topContainerWrapper: {
    display: "flex",
    gap: "17px",
    marginBottom: "10px",
    marginTop: "10px",
    "&>span": {
      flexGrow: "1",
    },
  },
  accordianWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: ({ isDesktop }) => (!isDesktop ? "20px" : "10px"),
  },
  mandatory: {
    color: "red",
    fontSize: 18,
    marginLeft: 2,
    lineHeight: "14px",
  },
  divWrapper: {
    display: "flex",
    height: "calc(100vh - 48px - 75px)",
    // overflowY:"hidden"
  },
  remove: {
    color: "#C0392B",

    "&:hover": {
      cursor: "pointer",
    },
  },
  notification: {
    display: "inline-flex",
    padding: "8px 12px",
    background: "#F6F4FF",
    borderRadius: "5px",
  },
  whiteBackground: {
    background: "#ffffff !important",
  },
  whiteBackgroundHeight: {
    background: "#ffffff !important",
    height: "43px",
    border: "1.5px solid #CFCDC9",
    borderRadius: "5px",
  },
  addBtn: {
    fontWeight: "500",
    fontSize: "17px",
    lineHeight: "17px",
    /* identical to box height */
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginBottom: "14px",
    marginTop: "12px",
    color: "#493AB1",

    "&:hover": {
      cursor: "pointer",
    },
  },
  footerRulesWrapper: {
    padding: "12px 17px",
    background: "#F6F4FF",
    color: "#493AB1",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    gap: "7px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonsArea: {
    background: "#F6F6F6",
    padding: "16px",
    borderRadius: "8px",
  },
  customFooter: {
    padding: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  personaliseWrapper: {
    color: "#4893FF",
    fontSize: "18px",
    fontWeight: "600",

    "&:hover": {
      cursor: "pointer",
    },
  },
  wordLimitCheckerWrapper: {
    color: "rgba(39, 37, 34, 0.3)",
    padding: "12px",
  },
  formWrapper: {
    flex: "1 1",
    padding: ({ isDesktop }) => (isDesktop ? "34px" : "17px"),
    background: "#EEECF8",
    overflow: "auto",
    // height: "fit-content",
  },
  buttonTypesRadioWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: "16px",
  },
  emptyDiv: {
    flexGrow: "1",
  },
  ctaButtonContainer: {
    ...(!isDesktop && {
      background: "#fff",
      borderTop: "1px solid #ccc",
      padding: 12,

      position: "fixed",
      bottom: 0,
      zIndex: 20,
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    }),
  },
  previewButton: {
    marginRight: "auto",
  },
  publishButton: { width: "max-content" },
  "publishButton span": { color: "#fff" },
  body: {
    height: "100%",
    display: "flex",
    flexGrow: 1,
    flexDirection: !isDesktop ? "column" : "row",
  },

  headerButtonsWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "30px",
  },
  typeWrapper: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    fontSize: "14px",
  },
  font14: {
    fontSize: "14px",
  },
  draggableWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  dragIcon: {
    marginTop: "24px",
    color: "#8C7EE8",
  },
  selectedColumnsWrapper: {
    flex: "1 1",
  },
  tabHeaderWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  entireFooterWrapper: {
    position: ({ isDesktop }) => (isDesktop ? "relative" : "fixed"),
    bottom: "0px",
    width: ({ isDesktop }) => !isDesktop && "100%",
  },
  boldHeader: {
    fontWeight: "600",
    fontSize: "14px",
    marginBottom: "0.5rem",
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
  },
  boldHeaderFirst: {
    fontWeight: "600",
    fontSize: "14px",
    marginBottom: "0.5rem",
    display: "flex",
    alignItems: "center",
  },
  buttonHeader: {
    fontWeight: "600",
    fontSize: "14px",
    marginBottom: "0.5rem",
    display: "flex",
    alignItems: "center",
    // marginTop: "16px",
  },
}));
