import ExlyModal from "common/Components/ExlyModal";

import React, { memo } from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useStyles from "./offeringNotifier.styles";

function OfferingNotifier({ isOpen, onClose, onPrimaryBtnClick }) {
  const isDesktop = useDesktopMediaQuery();

  const classes = useStyles({ isDesktop });

  return (
    <ExlyModal
      title={"Note"}
      open={isOpen}
      onClose={onClose}
      primaryBtnText={"Okay, got it"}
      onPrimaryBtnClick={() => {
        onPrimaryBtnClick();
        onClose();
      }}
      onSecondaryBtnClick={onClose}
      modal_props={{
        backDropClickClose: true,
      }}
      className={classes.desktopModalPaper}
      mobile_modal_props={{
        paperClassName: classes.mobile_modal_paper,
      }}
      modalPaperClassName={classes.desktopModalPaper}
      paperClassName={classes.mobile_modal_paper}
      backDropClickClose
    >
      <div>
        <strong>Offering location </strong> variable will only be applicable for
        offerings that have meeting links (i.e. Zoom, Google meet, or online
        meeting link).
      </div>
    </ExlyModal>
  );
}

export default memo(OfferingNotifier);
