import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@my-scoot/component-library-legacy";
import React from "react";
import { table_col_keys } from "../../Components/ListingPage/tableConfig";
import { TEMPLATE_TYPE_LABEL } from "./WhatsappTemplateTypeColumn.constants";

export const WhatsappTemplateTypeColumn = (record) => {
  const {
    [table_col_keys.notification_type]: type,
    [table_col_keys.cdb_type]: cdbType,
  } = record;
  return (
    <>
      {TEMPLATE_TYPE_LABEL[type]}{" "}
      {cdbType && (
        <Tooltip title={cdbType} arrow color="primary">
          <InfoOutlined className="tw-opacity-50 !tw-text-xl" />
        </Tooltip>
      )}
    </>
  );
};
