import React from "react";
import { useStyles } from "./styles";

const WordLimitChecker = ({ counter, limit }) => {
  const classes = useStyles();

  return (
    <div className={classes.wordLimitCheckerWrapper}>
      <span>
        {counter || 0}/{limit}
      </span>
    </div>
  );
};

export default WordLimitChecker;
