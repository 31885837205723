import { Tooltip } from "@my-scoot/component-library-legacy";
import React, { Fragment } from "react";

const TooltipContainer = ({ tooltip, children }) => {
  return (
    <Tooltip title={tooltip} color="primary" arrow>
      <div>{children}</div>
    </Tooltip>
  );
};

export const TooltipWrapper = ({ tooltip, children }) => {
  const Wrapper = tooltip ? TooltipContainer : Fragment;
  const props = tooltip ? { tooltip } : {};

  return <Wrapper {...props}>{children}</Wrapper>;
};
