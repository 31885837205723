import ExlyModal from "common/Components/ExlyModal";

import React, { memo } from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useStyles from "./saveDraft.styles";
import { is_empty } from "utils/validations";
import { notification_color_keys, useNotifications } from "utils/hooks";

function SaveAsDraftModal({
  isOpen,
  onClose,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  templateName,
  primaryBtnProps,
  secondaryBtnProps,
}) {
  const isDesktop = useDesktopMediaQuery();
  const { notify } = useNotifications();

  const classes = useStyles({ isDesktop });

  return (
    <ExlyModal
      title={"Wait up!"}
      open={isOpen}
      onClose={onClose}
      primaryBtnText={"Save as draft"}
      onPrimaryBtnClick={() =>
        !is_empty(templateName)
          ? onPrimaryBtnClick()
          : notify(
              `Please fill the Template name to save the template as a draft`,
              notification_color_keys.warning
            )
      }
      onSecondaryBtnClick={onSecondaryBtnClick}
      secondaryBtnText={
        <div className={classes.modalSecondaryBtn}>Leave without saving</div>
      }
      showSecondaryBtn={true}
      modal_props={{
        backDropClickClose: true,
      }}
      className={classes.desktopModalPaper}
      mobile_modal_props={{
        paperClassName: classes.mobile_modal_paper,
        keepMounted: true,
        secondaryAction: {
          label: (
            <div className={classes.modalSecondaryBtn}>
              Leave without saving
            </div>
          ),
          onClick: onSecondaryBtnClick,
          className: classes.mobileModalFooter,
        },
      }}
      modalPaperClassName={classes.desktopModalPaper}
      paperClassName={classes.mobile_modal_paper}
      primaryBtnProps={primaryBtnProps}
      secondaryBtnProps={secondaryBtnProps}
      backDropClickClose
    >
      <div className={classes.modalBody}>
        <strong>Would you like to save your progress?</strong> We’d recommend
        you save it as draft. You can find it later under the{" "}
        <strong> Drafts </strong>tab.
      </div>
    </ExlyModal>
  );
}

export default memo(SaveAsDraftModal);
