import ExlyModal from "common/Components/ExlyModal";

import React, { memo } from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useStyles from "./approvalModal.styles";

function ApprovalModal({
  isOpen,
  primaryBtnProps,
  secondaryBtnProps,
  onClose,
  onPrimaryBtnClick,
}) {
  const isDesktop = useDesktopMediaQuery();

  const classes = useStyles({ isDesktop });

  return (
    <ExlyModal
      title={"Submit for approval"}
      open={isOpen}
      onClose={onClose}
      primaryBtnText={"Yes, submit"}
      onPrimaryBtnClick={onPrimaryBtnClick}
      onSecondaryBtnClick={onClose}
      secondaryBtnText={"Cancel"}
      modal_props={{
        backDropClickClose: true,
      }}
      className={classes.desktopModalPaper}
      mobile_modal_props={{
        paperClassName: classes.mobile_modal_paper,
      }}
      modalPaperClassName={classes.desktopModalPaper}
      paperClassName={classes.mobile_modal_paper}
      primaryBtnProps={primaryBtnProps}
      secondaryBtnProps={secondaryBtnProps}
      backDropClickClose
    >
      <div>
        <div>
          Your message template will be sent to Meta for approval. Once
          approved, you can use this template in your WhatsApp Broadcasts.
        </div>
        <div className={classes.notification}>
          <strong>Please note:</strong> Approval time may take 30 mins to 2
          business days. (Subject to Meta team approval)
        </div>
        <div>
          <strong>Do you want to submit this template for approval?</strong>
        </div>
      </div>
    </ExlyModal>
  );
}

export default memo(ApprovalModal);
