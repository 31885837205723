import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    desktopModalPaper: {
      width: "100%",
      padding: ({ isDesktop }) =>
        isDesktop ? "20px !important" : "20px 18px !important",
      fontWeight: "400",
    },

    mobile_modal_paper: {
      padding: ({ isDesktop }) => (isDesktop ? "20px" : "0px"),
      fontWeight: "400",
    },
    notification: {
      padding: "8px 12px",
      background: "#F6F4FF",
      borderRadius: "5px",
      marginTop: "12px",
      marginBottom: "20px",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
