import { feature_activation_keys } from "features/FeatureActivationStatus/FeatureActivationStatus.constants";
import useFeaturesActivationStatus from "features/FeatureActivationStatus/utils/useFeaturesActivationStatus";
import { useEffect, useState } from "react";
import { exly_creator_name } from "ui/pages/whatsappBroadcast/create/CustomTemplates/moduleConstants";
import { useWhatsAppHelpers } from "ui/pages/whatsappBroadcast/useWhatsAppHelpers";
import {
  green_tick_status_consts,
  whatsapp_platform_choices_consts,
} from "ui/pages/whatsappBroadcast/whatsappBroadcast.constants";
import { getWhatsappWhiteLabelData } from "ui/pages/whatsappBroadcast/whatsappBroadcastStorageUtils";
import exlyIcon from "assets/images/exly-whatsapp-dp.jpg";
import { is_empty } from "features/Common/utils/common.utils";
import { useToggleState } from "utils/hooks";

export const useWhatsappProfile = ({ isPreview, platform } = {}) => {
  const [profilePic, setProfilePic] = useState("");
  const [name, setName] = useState("");
  const [verified, setIsVerified] = useState(false);
  const [showAvatar, setShowAvatar] = useState(false);

  const [isLoadingProfile, startProfileLoading, stopProfileLoading] =
    useToggleState(false);
  const { loading, featuresActivationStatus } = useFeaturesActivationStatus();
  const { is_whitelabelled_whatsapp_allowed } = feature_activation_keys;
  const { getWhatsappWhiteLabelDetails } = useWhatsAppHelpers();
  const whatsappWhiteLabelData = getWhatsappWhiteLabelData();

  const isAiSensyEnabled =
    !loading &&
    featuresActivationStatus[is_whitelabelled_whatsapp_allowed.key] &&
    whatsappWhiteLabelData?.has_migrated_to_aisensy;

  const isSentThroughAiSensy =
    platform === whatsapp_platform_choices_consts.AISENSY;

  /**  Need to give sync option when !isPreview (edit/duplicate etc), isPreview mode but isSentThroughAiSensy */
  const isProfileSyncNeeded =
    (!isPreview || (isPreview && isSentThroughAiSensy)) && isAiSensyEnabled;
  useEffect(() => {
    if (!loading) {
      if (isProfileSyncNeeded) {
        startProfileLoading();
        fetchWAProfileDetails().finally(stopProfileLoading);
      } else {
        setName(exly_creator_name);
        setProfilePic(exlyIcon);
      }
    }
  }, [isProfileSyncNeeded, loading]);

  const fetchWAProfileDetails = async () => {
    const whatsappWhiteLabelData = await getWhatsappWhiteLabelDetails({
      fetch_latest_profile: true,
    });

    if (
      !is_empty(whatsappWhiteLabelData) &&
      whatsappWhiteLabelData.has_migrated_to_aisensy
    ) {
      const displayImage = whatsappWhiteLabelData.wa_display_image;
      displayImage ? setProfilePic(displayImage) : setShowAvatar(true);
      setName(whatsappWhiteLabelData.wa_display_name);
      setIsVerified(
        whatsappWhiteLabelData.green_tick_status ===
          green_tick_status_consts.ACCEPTED
      );
    }
  };

  return {
    profilePic,
    name,
    verified,
    showAvatar,
    isProfileSyncNeeded,
    isLoadingProfile,
    setProfilePic,
    setShowAvatar,
    fetchWAProfileDetails,
  };
};
