import React from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import { useStyles } from "../styles";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import classnames from "classnames";

export const EmptyStateDoc = ({ className }) => {
  const isDesktop = useDesktopMediaQuery();
  const style = useStyles({ isDesktop })();

  return (
    <div className={classnames(style.uploadDocContainer, className)}>
      <div>
        <DescriptionIcon />
        <span className={style.docText}>No document added yet</span>
      </div>
    </div>
  );
};
