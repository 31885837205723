import VisibilityIcon from "@material-ui/icons/Visibility";
import { Chip, Tooltip } from "@my-scoot/component-library-legacy";
import CopyIcon from "assets/vectors/copy_new.svg";
import DeleteIcon from "assets/vectors/delete_red.svg";
import EditIcon from "assets/vectors/edit.svg";
import PreviewIcon from "assets/vectors/expand_content.svg";
import OpenDrawer from "assets/vectors/open_drawer.svg";
import WhatsappIcon from "assets/vectors/whatsapp_nofill.svg";
import classnames from "classnames";
import ExlyDeleteModal from "common/Components/ExlyDeleteModal/ExlyDeleteModal";
import ExlyImage from "common/Components/ExlyImage";
import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import React from "react";
import { useRefresh } from "react-admin";
import { useHistory } from "react-router";
import {
  notification_color_keys,
  useNotifications,
  useToggleState,
} from "utils/hooks";
import {
  PROMOTIONAL_TEMPLATE_TOOLTIP,
  statusLabelMapping,
} from "../../whatsappBroadcast.constants";
import { useStyles } from "./listingPage.styles";

import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { MobilePopover } from "@my-scoot/component-library-legacy";
import { TEMPLATE_STEPS } from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import { redirectToCreatePresetNotificationTemplates } from "features/CreateWhatsappPresetNotificationTemplate/utils/CreateWhatsappPresetNotificationTemplate.utils";
import {
  WHATSAPP_BROADCAST_CUSTOM_ROUTE_KEYS,
  WHATSAPP_BROADCAST_ROUTE_GENERATORS,
  WHATSAPP_BROADCAST_ROUTE_IDS,
} from "features/WhatsappBroadcast/constants/WhatsappBroadcast.urlPaths";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useDispatch } from "react-redux";
import { setCommDataSession } from "ui/pages/schedule/MangageCommunication/mangageCommunication.helpers";
import {
  deleteModalCopy,
  templateActionTypes,
  templateApprovalStatus,
} from "../../create/CustomTemplates/moduleConstants";
import { CUSTOM_TEMPLATE_TYPES } from "../../modules/CreateCustomTemplateModal/constants/CreateCustomTemplateModal.constants";
import { TooltipWrapper } from "../../modules/TooltipWrapper/TooltipWrapper";
import { SET_TEMPLATE_ACTION_TYPE } from "../../redux/actions";
import { useWhatsAppHelpers } from "../../useWhatsAppHelpers";
import { whatsapp_broadcasts_apis } from "../../whatsappBroadCast.api";
import { getWhatsappWhiteLabelData } from "../../whatsappBroadcastStorageUtils";
import CopyModal from "../CopyModal";
import styles from "./ListingPage.module.css";
import { table_col_keys } from "./tableConfig";

export const TemplatesActionField = withComponentLibraryTheme(
  ({ record = {} }) => {
    const isDesktop = useDesktopMediaQuery();
    const classes = useStyles({ isDesktop });
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [isCopyOpen, openCopyModal, hideCopyModal] = useToggleState(false);
    const { notify } = useNotifications();
    const {
      uuid,
      approval_status,
      [table_col_keys.notification_type]: templateNotificationType,
    } = record || {};
    const isPromotionalTemplate =
      templateNotificationType === CUSTOM_TEMPLATE_TYPES.PROMOTIONAL;

    const { hasWhatsappWriteAccess, checkIsAiSensyBlockedAfterEnabling } =
      useWhatsAppHelpers();
    const whatsappWhiteLabelData = getWhatsappWhiteLabelData();

    const isAiSensyBlocked = checkIsAiSensyBlockedAfterEnabling(
      whatsappWhiteLabelData
    );

    const refresh = useRefresh();
    const history = useHistory();

    const dispatch = useDispatch();

    const handleDelete = async () => {
      if (!hasWhatsappWriteAccess) return;
      try {
        const bodyParams = {
          template_uuid: uuid,
        };

        const response = await dataProvider.custom_request(
          whatsapp_broadcasts_apis.delete_custom_template,
          apiMethods.POST,
          { ...bodyParams }
        );

        if (response.status === 200) {
          notify("Template deleted", notification_color_keys.info);
          refresh();
        }
      } catch (err) {
        notify(
          err.message || "Error while deleting the template",
          notification_color_keys.error
        );
      } finally {
        setShowDeleteModal(false);
      }
    };

    const onCopyClicked = () => {
      if (!hasWhatsappWriteAccess || isAiSensyBlocked) return;

      if (isPromotionalTemplate) {
        dispatch({
          type: SET_TEMPLATE_ACTION_TYPE,
          payload: templateActionTypes.Copy,
        });
        history.push(
          WHATSAPP_BROADCAST_ROUTE_GENERATORS[
            WHATSAPP_BROADCAST_CUSTOM_ROUTE_KEYS.CREATE_WHATSAPP_BROADCAST
          ]({ templateUuid: uuid })
        );
      } else {
        redirectToCreatePresetNotificationTemplates({
          history,
          step: TEMPLATE_STEPS.TEMPLATE_DETAILS,
          template: uuid,
        });
      }
    };

    const onBroadCastClicked = () => {
      if (!hasWhatsappWriteAccess || isAiSensyBlocked) return;
      // fromTemplatePage indicate that broadcast being created via myTemplates of sales & marketing page
      const commListingData = {
        ...record,
        isCustomTemplate: true,
        fromTemplatesPage: true,
      };
      setCommDataSession(commListingData);
      history.push(
        WHATSAPP_BROADCAST_ROUTE_IDS[
          WHATSAPP_BROADCAST_CUSTOM_ROUTE_KEYS.PUBLISH_WHATSAPP_BROADCAST
        ]
      );
    };

    const onMobilePreviewClicked = () => {
      if (!hasWhatsappWriteAccess) return;

      dispatch({
        type: SET_TEMPLATE_ACTION_TYPE,
        payload: templateActionTypes.Preview,
      });

      history.push(
        WHATSAPP_BROADCAST_ROUTE_GENERATORS[
          WHATSAPP_BROADCAST_CUSTOM_ROUTE_KEYS.CREATE_WHATSAPP_BROADCAST
        ]({ templateUuid: uuid })
      );
    };

    const onPreviewClicked = (e, disabledAction = false) => {
      if (!hasWhatsappWriteAccess || disabledAction) return;

      if (isPromotionalTemplate) {
        dispatch({
          type: SET_TEMPLATE_ACTION_TYPE,
          payload: templateActionTypes.ViewTepmaple,
        });
        history.push(
          WHATSAPP_BROADCAST_ROUTE_GENERATORS[
            WHATSAPP_BROADCAST_CUSTOM_ROUTE_KEYS.CREATE_WHATSAPP_BROADCAST
          ]({ templateUuid: uuid })
        );
      } else {
        redirectToCreatePresetNotificationTemplates({
          history,
          step: TEMPLATE_STEPS.TEMPLATE_DETAILS,
          template: uuid,
          readOnly: true,
        });
      }
    };

    const handleEdit = () => {
      if (!hasWhatsappWriteAccess || isAiSensyBlocked) return;

      if (isPromotionalTemplate) {
        dispatch({
          type: SET_TEMPLATE_ACTION_TYPE,
          payload: templateActionTypes.ViewTepmaple,
        });
        history.push(
          WHATSAPP_BROADCAST_ROUTE_GENERATORS[
            WHATSAPP_BROADCAST_CUSTOM_ROUTE_KEYS.CREATE_WHATSAPP_BROADCAST
          ]({ templateUuid: uuid })
        );
      } else {
        redirectToCreatePresetNotificationTemplates({
          history,
          step: TEMPLATE_STEPS.TEMPLATE_DETAILS,
          template: uuid,
        });
      }
    };

    return (
      <>
        <div className={classes.info_field_wrapper}>
          {!isDesktop && approval_status !== templateApprovalStatus.Draft && (
            <div
              onClick={onMobilePreviewClicked}
              className={!isDesktop && classes.mobileWrapper}
            >
              <ExlyImage
                src={PreviewIcon}
                alt="PreviewIcon"
                height={20}
                width={20}
              />
              {!isDesktop && <span>Preview message</span>}
            </div>
          )}
          {approval_status === templateApprovalStatus.Approved && (
            <div
              onClick={isPromotionalTemplate ? onBroadCastClicked : undefined}
              className={classnames(
                classes.actionStyle,
                !isDesktop && classes.mobileWrapper,
                isAiSensyBlocked && classes.disabledAction,
                !isPromotionalTemplate && "disabled"
              )}
            >
              <div className="d-flex align-items-center justify-content-between w-100">
                <div
                  className={classnames(
                    "d-flex align-items-center",
                    styles.whatsapp_action
                  )}
                >
                  <TooltipWrapper
                    tooltip={
                      isPromotionalTemplate
                        ? undefined
                        : "You won’t be able to send broadcasts using this template as it was made for Preset Notifications"
                    }
                  >
                    <ExlyImage
                      src={WhatsappIcon}
                      alt="WhatsappIcon"
                      height={20}
                      width={20}
                    />
                  </TooltipWrapper>

                  {!isDesktop && <span>Use this template</span>}
                </div>

                {!isDesktop && !isPromotionalTemplate && (
                  <Tooltip
                    title={PROMOTIONAL_TEMPLATE_TOOLTIP}
                    color="primary"
                    arrow
                  >
                    <InfoOutlined />
                  </Tooltip>
                )}
              </div>
            </div>
          )}

          {approval_status !== templateApprovalStatus.Draft ? (
            <Tooltip
              title={"View template"}
              placement="top"
              color="primary"
              arrow
            >
              <div
                onClick={onPreviewClicked}
                className={classnames(
                  classes.actionStyle,
                  !isDesktop && classes.mobileWrapper
                )}
              >
                <VisibilityIcon />
                {!isDesktop && <span>View template</span>}
              </div>
            </Tooltip>
          ) : (
            <div
              onClick={handleEdit}
              className={classnames(
                classes.actionStyle,
                !isDesktop && classes.mobileWrapper,
                isAiSensyBlocked && classes.disabledAction
              )}
            >
              <ExlyImage src={EditIcon} alt="EditIcon" height={20} width={20} />
              {!isDesktop && <span>Edit template</span>}
            </div>
          )}
          <div
            onClick={() => {
              approval_status !== templateApprovalStatus.Draft &&
              !isAiSensyBlocked
                ? openCopyModal()
                : onCopyClicked();
            }}
            className={classnames(
              classes.actionStyle,
              !isDesktop && classes.mobileWrapper,
              isAiSensyBlocked && classes.disabledAction
            )}
          >
            <ExlyImage src={CopyIcon} alt="CopyIcon" height={20} width={20} />
            {!isDesktop && <span>Duplicate template</span>}
          </div>
          <div
            onClick={() => {
              setShowDeleteModal(true);
            }}
            className={classnames(
              classes.actionStyle,
              !isDesktop && classes.deleteMobileWrapper
            )}
          >
            <ExlyImage
              src={DeleteIcon}
              alt="DeleteIcon"
              height={20}
              width={20}
            />
            {!isDesktop && <span>Delete template</span>}
          </div>
        </div>
        <ExlyDeleteModal
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          showWarningIcon={false}
          onDelete={handleDelete}
          title="Are you sure?"
          description={deleteModalCopy[approval_status]}
        />
        {isCopyOpen && (
          <CopyModal
            isOpen={isCopyOpen}
            onPrimaryBtnClick={() => onCopyClicked()}
            onClose={hideCopyModal}
            approvalStatus={approval_status}
          />
        )}
      </>
    );
  }
);

export const TemplateStatusChip = ({ status }) => {
  const classes = useStyles();

  return (
    <Chip
      chipClassName={classnames(
        classes.template,
        classes[`template_${status}`]
      )}
      label={statusLabelMapping[status]}
    />
  );
};

/*
Mobile actions (opening in mobile drawer)
*/

export function MobileActionDrawer({ record, label }) {
  const [open, openMobileActions, hideModal] = useToggleState(false);
  const classes = useStyles();

  return (
    <>
      <div onClick={openMobileActions} className={classes.labelWrapper}>
        {label} <ExlyImage src={OpenDrawer} alt={"OpenDrawer"} />{" "}
      </div>
      <MobilePopover open={open} onClose={hideModal}>
        <div className={classes.wrap}>
          <TemplatesActionField record={record} />
        </div>
      </MobilePopover>
    </>
  );
}
