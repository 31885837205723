import { checkIsDefined } from "@my-scoot/exly-react-component-lib";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { ExlyModal } from "features/Common/modules/ExlyModal/ExlyModal";
import { redirectToCreatePresetNotificationTemplates } from "features/CreateWhatsappPresetNotificationTemplate/utils/CreateWhatsappPresetNotificationTemplate.utils";
import LinkTypeTile from "features/PaymentLinks/modules/CreatePaymentLinkModal/pages/LinkTypeSelection/components/LinkTypeTile/LinkTypeTile";
import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  CUSTOM_TEMPLATE_TYPE_OPTIONS,
  CUSTOM_TEMPLATE_TYPES,
} from "./constants/CreateCustomTemplateModal.constants";
import styles from "./CreateCustomTemplateModal.module.css";

export const CreateCustomTemplateModal = ({ open, onClose }) => {
  const history = useHistory();
  const [selectedTemplateType, setSelectedTemplateType] = useState();

  const handleCreatePromotionalTemplates = () => {
    history.push(`${app_route_ids[app_route_keys.whatsapp_template]}`);
  };

  const handleCreatePresetNotificationTemplates = () => {
    redirectToCreatePresetNotificationTemplates({ history });
  };

  const handleContinue = {
    [CUSTOM_TEMPLATE_TYPES.PROMOTIONAL]: handleCreatePromotionalTemplates,
    [CUSTOM_TEMPLATE_TYPES.PRESET_NOTIFICATION]:
      handleCreatePresetNotificationTemplates,
  }[selectedTemplateType];

  return (
    <ExlyModal
      title="Choose Template Type"
      primaryBtnText="Continue"
      open={open}
      onClose={onClose}
      className={styles.root}
      showSecondaryBtn={false}
      onPrimaryBtnClick={handleContinue}
      primaryBtnProps={{
        fullWidth: true,
        disabled: !checkIsDefined(selectedTemplateType),
      }}
    >
      {CUSTOM_TEMPLATE_TYPE_OPTIONS.map((option) => (
        <LinkTypeTile
          key={option.value}
          onClick={setSelectedTemplateType}
          active={selectedTemplateType === option.value}
          {...option}
        />
      ))}
    </ExlyModal>
  );
};
