import React from "react";
import { useStyles } from "../styles";
import AddPhotoAlternateIcon from "@material-ui/icons/InsertPhoto";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import classnames from "classnames";

export const EmptyStateImage = ({ className }) => {
  const isDesktop = useDesktopMediaQuery();
  const style = useStyles({ isDesktop })();

  return (
    <div className={classnames(style.imageContainer, className)}>
      <div>
        <AddPhotoAlternateIcon />
        <div className={style.imageText}>No image added yet</div>
      </div>
    </div>
  );
};
