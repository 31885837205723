import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    desktopModalPaper: {
      width: "100%",
      padding: ({ isDesktop }) =>
        isDesktop ? "20px !important" : "20px 18px !important",
      fontWeight: "400",
    },
    modalSecondaryBtn: {
      color: "#493AB1",
    },
    mobile_modal_paper: {
      fontWeight: "400",
      padding: ({ isDesktop }) => (isDesktop ? "20px" : "0px"),
    },

    mobileModalFooter: {
      paddingLeft: "0px !important",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
