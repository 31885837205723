import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import { generateLinkTypeOption } from "features/PaymentLinks/modules/CreatePaymentLinkModal/utils/CreatePaymentLinkModal.utils";

export const CUSTOM_TEMPLATE_TYPES = {
  PROMOTIONAL: 1,
  PRESET_NOTIFICATION: 2,
};

export const CUSTOM_TEMPLATE_TYPE_LABELS = {
  [CUSTOM_TEMPLATE_TYPES.PROMOTIONAL]: "Promotional & Other Templates",
  [CUSTOM_TEMPLATE_TYPES.PRESET_NOTIFICATION]: "Preset Notification Templates",
};

export const CUSTOM_TEMPLATE_TYPE_OPTIONS = [
  generateLinkTypeOption({
    title: CUSTOM_TEMPLATE_TYPE_LABELS[CUSTOM_TEMPLATE_TYPES.PROMOTIONAL],
    description:
      "Choose this to create marketing & utility templates for Broadcasts and other messages",
    value: CUSTOM_TEMPLATE_TYPES.PROMOTIONAL,
    icon: getS3AssetCDNUrl(
      "assets/vectors/sales_and_marketing/broadcast/promotional_templates.svg"
    ),
  }),
  generateLinkTypeOption({
    title:
      CUSTOM_TEMPLATE_TYPE_LABELS[CUSTOM_TEMPLATE_TYPES.PRESET_NOTIFICATION],
    description:
      "Use this to create templates for your purchase confirmation and reminder comms",
    value: CUSTOM_TEMPLATE_TYPES.PRESET_NOTIFICATION,
    icon: getS3AssetCDNUrl(
      "assets/vectors/sales_and_marketing/broadcast/preset_notification_templates.svg"
    ),
  }),
];
